<template>
  <!--begin::Card-->
  <div class="card card-custom" :class="{ 'text-right': $vuetify.rtl }">
    <!--begin::Header-->
    <h2
      class="mt-4"
      :class="{
        'mr-5': $vuetify.rtl,
        'ml-5': !$vuetify.rtl,
      }"
    >
      {{ $t("change_password") }}
    </h2>
    <hr />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_password_change_form">
      <div class="col col-md-10">
        <div class="card-body">
          <div class="d-md-flex mb-0">
            <label
              for="current_password"
              class="font-weight-bolder font-size-lg mr-md-10 mt-3 w-140px"
              >{{ $t("current_password") }}</label
            >
            <!-- Current password -->
            <input
              id="current_password"
              :class="[
                'form-control form-control-lg form-control-solid  rounded-lg bg-light-o-60',
                current_password_empty ? 'border-danger' : 'border-gray-400',
              ]"
              type="password"
              v-model="formData.current_password"
              name="current_password"
              ref="current_password"
            />
          </div>
          <!-- Forgot password -->
          <div class="d-md-flex mb-5">
            <div class="mr-md-3 w-140px"></div>
            <a
              @click.prevent="forgotPassword"
              class="text-center text-sm font-weight-bold"
            >
              {{ $t("forgot_password") }}
            </a>
          </div>
          <!-- New password -->
          <div class="d-md-flex mb-5">
            <label
              for="new_password"
              class="font-weight-bolder font-size-lg mr-md-10 mt-3 w-140px"
              >{{ $t("new_password") }}</label
            >
            <input
              id="new_password"
              :class="[
                'form-control form-control-lg form-control-solid  rounded-lg bg-light-o-60',
                new_password_empty ? 'border-danger' : 'border-gray-400',
              ]"
              type="password"
              value=""
              name="new_password"
              ref="new_password"
              v-model="formData.new_password"
            />
          </div>
          <!-- Confirm password -->
          <div class="d-md-flex mb-1">
            <label
              for="confirm_password"
              class="font-weight-bolder font-size-lg mr-md-10 mt-3 w-140px"
              >{{ $t("verify_password") }}</label
            >
            <input
              id="confirm_password"
              :class="[
                'form-control form-control-lg form-control-solid rounded-lg bg-light-o-60',
                password_mismatch === true
                  ? 'border-danger'
                  : 'border-gray-400',
                password_mismatch === false && 'border-success',
              ]"
              type="password"
              value=""
              name="verify_password"
              ref="verify_password"
              v-model="confirm_password"
              @input="comparePassword"
            />
          </div>
          <div v-if="this.password_mismatch" class="d-md-flex mb-5">
            <div class="mr-md-3 w-140px"></div>
            <p class="text-danger">{{ $t("password_mismatch") }}</p>
          </div>
          <!-- Submit -->
          <div class="d-flex justify-content-end mt-10">
            <b-button
              pill
              class="mt-3 iq-btn"
              @click="save()"
              ref="kt_save_changes"
              >{{ $t("save_changes") }}</b-button
            >
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PASSWORD } from "@/core/services/store/auth.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import ApiService from "@/core/services/api.service";
import axios from "axios";
import SwalService from "@/core/services/swal.service";

export default {
  name: "ChangePassword",
  data() {
    return {
      formData: {
        current_password: null,
        new_password: null,
      },
      confirm_password: null,
      password_mismatch: null,
      current_password_empty: false,
      new_password_empty: false,
    };
  },

  methods: {
    save() {
      this.verify();
      this.comparePassword();
      if (
        this.formData.current_password &&
        this.formData.new_password &&
        !this.password_mismatch
      ) {
        this.$store
          .dispatch(UPDATE_PASSWORD, this.formData)
          // go to which page after successfully update
          .then((response) => {
            // this.$router.push({ name: "profile" });
            if (response) {
              SwalService.successMessage({
                html: this.$t("password_updated"),
              });
              this.formData = {
                current_password: null,
                new_password: null,
              };
              this.confirm_password = null;
              this.password_mismatch = null;
              this.current_password_empty = false;
              this.new_password_empty = false;
            } else {
              SwalService.errorMessage({
                html: this.$t("please_provide_correct_data"),
              });
            }
            // console.log(response);
            return true;
          });
      }
    },

    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    },

    forgotPassword() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { email: this.currentUser.data.email };
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/auth/password/reset`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: data,
      };
      axios(config)
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          SwalService.successMessage({
            html: this.$t("check_your_mail"),
          });
        })
        .catch((response) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          if (!response) {
            SwalService.errorMessage({
              html: this.$t("no_internet_connection"),
            });
          } else {
            // console.log(response);
            SwalService.successMessage({
              html: this.$t("check_your_mail"),
            });
          }
        });
    },

    verify() {
      this.current_password_empty = !this.formData.current_password
        ? true
        : false;
      this.new_password_empty = !this.formData.new_password ? true : false;
    },

    comparePassword() {
      if (this.confirm_password === null) {
        this.password_mismatch = true;
        return;
      }
      this.password_mismatch =
        this.confirm_password === this.formData.new_password ? false : true;
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
